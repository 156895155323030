<template>
  <b-card title="">
    <div class="card-title">
      <div class="row">
        <div class="col-md-6">
          <h4 class="mb-0">{{ title }}</h4>
        </div>
        <div class="col-md-6 text-right">
          <button
            class="btn btn-info btn-icon mr-1 d-none"
            @click.prevent="displayForm = !displayForm"
          >
            <feather-icon icon="PlusIcon" />
          </button>
          <button
            class="btn btn-info btn-icon"
            @click.prevent="displayFilters = !displayFilters"
          >
            <feather-icon icon="FilterIcon" />
          </button>
        </div>
      </div>
    </div>
    <div class="m-lg-5 mb-5">
      <orders-filters
        v-if="displayFilters"
        :filters.sync="filters"
        @updated="setPage(1)"
      />
    </div>
    <div v-if="displayForm == true" class="m-lg-5 mb-5">
      <h3>Ajouter un client</h3>
      <order-form
        :creation="true"
        :display.sync="displayForm"
        @created="onCreate"
      />
    </div>
    <table class="table table-hover">
      <tbody>
        <template v-for="order in orders">
          <tr
            :key="order.id"
            @click.prevent="$router.push(`/orders/${order.id}`)"
          >
            <td>
              <h4>
                {{ $dayjs(order.created_at).format('DD-MM-YYYY') }}
              </h4>
            </td>
            <td>
              {{ $t(`ORDER.${order.status}`) }}
            </td>
            <td>
              {{ order.user.fullname }}
            </td>
            <td class="text-right">
              {{ order.grand_total | currency }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <pagination-custom
      :page="filters.page"
      :total="pagination.total"
      :per-page="filters.per_page"
      class="text-center mt-3"
      @change="setPage"
    />
    <!-- <pre>{{ orders }}</pre> -->
    <!-- <pre>{{ pagination }}</pre> -->
    <!-- <pre>{{ filters }}</pre> -->
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import OrdersFilters from './OrdersFilters.vue'

const orderFields =
  'results{' +
  'id,status,created_at,grand_total,' +
  'user{fullname},' +
  '},meta'

export default {
  components: {
    BCard,
    OrdersFilters,
  },
  data() {
    return {
      displayFilters: false,
      displayForm: false,
      orders: [],
      pagination: { total: 0 },
      filters: {
        page: 1,
        per_page: 10,
        term: null,
      },
    }
  },
  computed: {
    title() {
      if (this.pagination.total) {
        return `${this.pagination.total} éléments - page ${this.pagination.page} / ${this.pagination.pages}`
      }
      return '…'
    },
  },
  watch: {
    $route() {
      this.init()
    },
  },
  async created() {
    this.filters = Object.assign(this.filters, this.$route.query)
    this.init()
  },
  methods: {
    async init() {
      const resp = await this.$http.get('/orders/', {
        params: Object.assign(this.filters, this.$route.query),
        headers: {
          'X-Fields': orderFields,
        },
      })
      this.orders = resp.data.results
      this.pagination = resp.data.meta
      this.$forceUpdate()
      this.scrollToTop()
    },
    setPage(page) {
      if (page) {
        this.filters.page = page
      }
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: this.filters,
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onCreate(order) {
      this.displayForm = false
      this.$router.push(`/orders/${order.id}/`)
    },
  },
}
</script>

<style></style>
