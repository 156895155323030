<template>
  <div>
    <b-form-group label-for="input-scheme">
      <b-form-select
        v-model="options.status"
        placeholder="Destination"
        @change="updateFilters()"
      >
        <template #first>
          <b-form-select-option :value="null" disabled>
            -- Filtrer par destination --
          </b-form-select-option>
        </template>
        <b-form-select-option
          v-for="option in constants.ORDER_STATUSES"
          :key="option"
          :value="option"
        >
          {{ $t(`ORDER.${option}`) }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>
    <!-- <pre>{{ constants }}</pre> -->
  </div>
</template>
<script>
import { BFormGroup, BFormSelect, BFormSelectOption } from 'bootstrap-vue'

export default {
  name: 'IngredientsFilters',
  components: {
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
  },
  props: {
    filters: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      constants: {},
      options: {
        like_term: null,
      },
    }
  },
  created() {
    this.options = Object.assign(this.options, this.filters)
    this.init()
  },
  methods: {
    async init() {
      const respConstants = await this.$http.get('/constants/', {
        params: {
          keys: 'ORDER_STATUSES',
        },
      })
      this.constants = respConstants.data
    },
    updateFilters() {
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: Object.assign(this.options, { page: 1 }),
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
  },
}
</script>
